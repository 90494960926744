export const support = {
  id: 'support',
  title: 'Cлужбу поддержки',
  content: `
    <h1>Если у вас возникли вопросы, Вы всегда можете обратиться в нашу службу поддержки.</h1>
    <p>Наша почта: <b><a href="mailto:support@rysebet.com">support@rysebet.com</a></b></p>
    <p>Наш телеграм: <b><a href="https://t.me/rysebett" target="_bkank">https://t.me/rysebett</a></b></p>
    <p>Аккаунт оператора, который доступен 24/7: <b><a href="https://t.me/rysebet_operator" target="_blank" rel="noreferrer">https://t.me/rysebet_operator</a></b></p>
  `
};
