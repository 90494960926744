
export const comonRules = {
  id: 'geberal-rules',
  title: 'Общие положения и условия',
  content: `
    <h1>Общие положения и условия</h1>
      <h2>1.Основные положения&nbsp;</h2>
      <ul>
        <li>1.1.Ставка – соглашение, касающееся выигрыша, заключаемое между клиентом и букмекерской
        компанией по установленным правилам; в данном случае, исход соглашения зависит от события,
        которое неизвестно произойдет или нет. Ставки от клиентов принимаются согласно условиям букмекерской компании.
        </li><li>1.2.Исход – результат события (событий) на которое делалась ставка.
        </li><li>1.3.Клиент – лицо делающее ставку в букмекерской компании на исход события.
        </li><li>1.4.Линия – совокупность событий, возможные исходы этих событий,
        коэффициенты на возможные исходы этих событий,
        их дата и время, после которых букмекерская компания прекращает прием ставок на исходы указанных событий.
        </li><li>1.5.Отмена ставки – событие, по которому не производятся расчет и выплата выигрыша.
        В случае «отмены ставки», согласно условиям настоящего Соглашения, сделка между организатором
        и клиентом считается незаключенной и по такой ставке производится возврат денежных средств.
        </li><li>1.6.Основное время матча – это продолжительность матча в соответствии с правилами проведения соревнования
        по данному виду спорта, включающее добавленное арбитром время. Основное время матча не включает дополнительное
        время, овертаймы, серии пенальти, штрафные броски и т.п.
        </li><li>1.7.Все ставки рассчитываются на основании информации, предоставляемой центром обработки информации.
        </li><li>1.8.При начислении выигрыша участник пари обязан проверить правильность начисленного выигрыша,
        а в случае несогласия по начисленному выигрышу уведомить букмекерскую компанию об этом, указав в
        обращении номер своего счета, дату, время, событие, сумму, выбранный исход события, коэффициент,
        а также причины несогласия с начисленным выигрышем. Все претензии по начисленным выигрышам
        принимаются в течение 10 (десяти) дней.
        </li><li>1.9.Ставка, сделанная клиентом на определенный исход события, считается выигранной,
        если правильно предсказаны все исходы, указанные в такой ставке.
        </li><li>1.10.Условия приема ставок (коэффициенты, форы, ставки на итоги, ограничения по максимальной ставке и т.д.)
         могут быть изменены после любой ставки, при этом условия ранее сделанных ставок остаются неизменными.
         Перед заключением соглашения клиент обязан выяснить все изменения в текущей линии.
        </li><li>1.11.Ставки, сделанные на события, исход которых известен на момент ставки,
        могут быть рассчитаны с коэффициентом «1».
        </li><li>1.12.В соответствии с настоящим Соглашением в случае разногласий, возникших
        между клиентом (участником соглашения) и букмекерской компанией, по вопросам,
        связанным с исполнением и реализацией заключенного между клиентом
         (участником соглашения) и букмекерской компанией соглашения о выигрыше, включая вопросы о выплате выигрыша,
         об исходе событий, коэффициентах выигрыша, вопросам иных существенных условий заключенного соглашения, а
         также по вопросам о признании соглашения незаключенным или недействительным, сторонами устанавливается
         обязательный претензионный порядок урегулирования возникших разногласий (досудебный порядок).
        </li><li>1.13.Претензия предъявляется в течение 10 (десяти) дней со дня, когда лицо узнало
        или должно было узнать о нарушении своего права. К претензии должны быть приложены все
        доводы и факты, подтверждающие и обосновывающие заявленные в ней требования. При отсутствии
        доводов, достаточных для обоснования указанных в претензии требований, претензия
        подлежит возврату без рассмотрения.
        </li><li>1.14.В случае ошибок персонала при приеме ставок (очевидные опечатки в предлагаемом перечне событий,
           несоответствие коэффициентов в линии и ставке и т.п.), при отступлении от настоящих Правил при приеме
            ставок, а также при других аргументах, подтверждающих некорректность ставок, букмекерская компания
             оставляет за собой право объявить ставки на таких условиях недействительными. Выплата по этим ставкам
              производится с коэффициентом «1».
        </li><li>1.15.В случае возникновения подозрений в нечестном характере матчей компания имеет право заблокировать
         ставки на спортивное событие до окончательного заключения международной организации и признать ставки
          недействительными в случае установления факта неспортивной борьбы. Выплата по этим ставкам производится
           с коэффициентом «1». Администрация не обязана представлять участникам пари доказательства и заключения.
        </li><li>1.16.В случае если в наших коэффициентах была очевидная ошибка,
         то такая ставка будет рассчитана в соответствии
         с заключительным результатом по актуальному коэффициенту, соответствующему данному маркеру.
        </li><li>1.17.Букмекерская компания не принимает претензии относительно разночтений при транслитерации
        (переводе с иностранных языков) названий команд, фамилий игроков, мест проведения соревнований.
        Вся информация, приведенная в названии турнира, носит вспомогательный характер. Любые возможные
        ошибки в этой информации не являются основанием для возврата ставок.
        </li><li>1.18.Компания оставляет за собой право закрыть игровой счет и аннулировать все сделанные на
        этом счете ставки, если будет установлено, что участник пари в момент постановки ставок обладал
         информацией о результате события. В этом случае, администрация букмекерской компании не обязана
          представлять участникам пари какие-либо доказательства и заключения.
        </li>
      </ul>

      <h2>2.Основные правила приема ставок</h2>
      <ul>
        <li>2.1.Букмекерская компания имеет право ограничивать максимальную ставку,
        коэффициенты на отдельные события, а также ограничивать или повышать максимальную
         ставку, коэффициенты любому конкретному клиенту без уведомления и объяснения причин.
        </li><li>2.2.Прием повторных ставок на один исход или комбинацию исходов от одного игрока может
         быть ограничен по решению букмекерской компании.
        </li><li>2.3.Ставка считается принятой после ее регистрации на сервере и выдачи подтверждения онлайн.
         Зарегистрированные ставки не подлежат отмене или исправлениям.
        </li><li>2.4.Ставки принимаются только в сумме, не превышающей текущий остаток на счете клиента.
         После регистрации ставки сумма ставки списывается со счета. После расчета ставок сумма
          выигрыша зачисляется на счет клиента.
        </li><li>2.5.Ставки принимаются до начала события; дата, время начала событий и любые связанные
         с ними комментарии, указанные в линии, являются ориентировочными. В случае если по
          каким-либо причинам ставка сделана после фактического начала события, такая ставка
           считается недействительной. Исключение составляют ставки на прямые трансляции, т.е.
            ставки, сделанные по ходу матча. Такие ставки считаются действительными до окончания матча.
        </li><li>2.6.Ставки LINE и LIVE не редактируются и не удаляются, кроме особых
        случаев предусмотренных настоящими правилами.
        </li><li>2.7.В случае если ставка подлежит отмене, то возврат денежных средств производится
         в одиночной ставке. В экспрессах, при отмене ставки по одному или нескольким событиям,
          расчет выигрыша по этим событиям не производится.
        </li><li>2.8.В случаях неправильно рассчитанных ставок (например, из-за ошибочно введенных результатов)
         такие ставки будут перерассчитаны. При этом ставки, сделанные между ошибочным расчетом и перерасчетом,
          считаются действительными. В случае если после перерасчета баланс игрока оказался отрицательным,
           он не может делать ставки, пока не пополнит свой счет.
        </li><li>2.9.Все спортивные события будут считаться перенесенными и отмененными только при наличии
         сведений из официальных документов организаций, проводящих спортивные соревнования, официальных
          сайтов спортивных федераций, сайтов спортивных клубов и других источников спортивной информации,
           и на основании этих данных корректируются спортивные события, указанные в линии.
        </li><li>2.10.Ставка подлежит отмене, если клиент сознательно вводил в заблуждение персонал
         (сотрудников букмекерской компании), предоставляя им ложные сведения и требования в
          отношении приема ставок, выплаты выигрыша, результата события и иные сведения и требования,
           имеющие такой характер. Вышеуказанные случаи действуют и в отношении несовершеннолетних лиц,
           не достигших 18-тилетнего возраста, а также их родителей.
        </li><li>2.11.Ставка подлежит отмене, если ставка сделана на известный исход
         (событие произошло, но результат не отразился в системе).
        </li>
      </ul>

      <h2>3.Виды ставок</h2>
      <ul>
        <li>3.1.Одиночная – ставка на определенный исход события. Выигрыш по одиночной ставке
         равен произведению суммы ставки на установленный для данного исхода коэффициент.
        </li><li>3.2.Экспресс – ставка на несколько независимых исходов событий. Выигрыш по экспрессу
         равен произведению суммы ставки на коэффициенты всех исходов, входящих в экспресс.
          Проигрыш по одному из исходов экспресса означает проигрыш по всему экспрессу.
        </li>
      </ul>

      <h2>4.Ограничения по включению некоторых исходов события</h2>
      <ul>
        <li>4.1.В экспресс можно включать только один из зависимых исходов. В случае включения в один экспресс
         двух и более зависимых событий, события с наименьшим коэффициентом исключаются из данного экспресса.
        </li><li>4.2.Зависимые исходы (взаимозависимые события) – результат одной
        части ставки влияет на результат другой части.
        </li><li>4.3.Ставки на события «Команда забьет пенальти Да/Нет» считаются проигрышными, если в основное
         время игры не было назначено пенальти.
        </li><li>4.4.Ставки на события «Как будет забит гол», «Следующий гол» считаются проигрышными,
        если гол, номер которого указан в ставке, не был забит.
        </li>
      </ul>

      <h2>5.Прием ставок по ходу матча (live-ставки)</h2>
      <ul>
        <li>5.1.Live-ставки принимаются на основные и дополнительные исходы. Можно делать
        одиночные live-ставки или объединять их в один экспресс.
        </li><li>5.2.Ставка считается принятой после ее регистрации на сервере и выдачи
        подтверждения онлайн. Принятая ставка изменению не подлежит. В случае
        наступления обстоятельств, указанных в разделе «Основные правила приема
         ставок», возможен расчет live-ставки с коэффициентом «1».
        </li><li>5.3.Букмекерская компания не несет ответственности за неточности в текущих
        результатах матчей по которым принимаются live-ставки. Клиенты должны также
        пользоваться другими независимыми источниками информации.
        </li><li>5.4.Live-ставки не редактируются и не удаляются.
        </li>
      </ul>

      <h2>6.Результаты матчей, дата и время их начала, порядок решения спорных вопросов</h2>
      <ul>
        <li>6.1.Расчет ставок может быть пересмотрен в связи с неверным
        результатом, предоставленным букмекером. При расчете ставок принимается
         во внимание фактическое время начала событий, которое ориентировочно
         определяется на основании официальных документов организаций, проводящих
          спортивные соревнования, и если нет таких утвержденных документов, то на
           основании официальных сайтов спортивных федераций, сайтов спортивных
           клубов и других источников спортивной информации.
        </li><li>6.2.Букмекерская компания не несет ответственности за несоответствие даты
         и времени реальному началу событий. Дата и время начала события, указываемые
          в линии, носят ориентировочный характер. При расчете ставок принимается
           фактическое время начала события, которое определяется на основании
            официальных документов организации, проводящей соревнование.
        </li><li>6.3.Букмекерская компания не несет ответственности за неточности в названии
         чемпионатов и продолжительности матчей (спортивных событий). Данные, указываемые
          в линии и прямой трансляции, носят ориентировочный характер. Клиентам необходимо
           пользоваться другими независимыми источниками информации для уточнения данных по
            спортивному событию. Претензии по результату события принимаются в течение 10
             календарных дней с момента завершения события при наличии официальных документов
              по результату события изданных организацией, проводящей соревнование.
        </li><li>6.4.Ставки, сделанные после начала события, рассчитываются с коэффициентом
        «1» (за исключением live-ставок); в экспрессах коэффициент по таким ставкам считается равным «1».
        </li><li>6.5.Если клиент сделал ставку на событие, результат которого ему был известен,
         такая ставка подлежит отмене. В этом случае решение букмекерской компанией принимается
          после специального закрытого расследования. Все действия в отношении такой
           ставки временно приостанавливаются.
        </li><li>6.6.Если участник отказывается от борьбы до начала события, то все ставки на этого участника аннулируются.
        </li><li>6.7.Если участник по какой-либо причине (травма, отказ и т.п.) выбывает по ходу
         соревнования, то все ставки, сделанные до начала последнего раунда или этапа
         соревнования, в котором он участвовал, считаются действительными,
          а остальные ставки аннулируются.
        </li><li>6.8.Если участник, член команды (футболист, хоккеист, баскетболист и т.д.)
         не принимал участия в матче, коэффициент выигрыша по ставкам на него
         принимается равным «1», если не оговорено другое.
        </li><li>6.9.Если в теннисном матче отказ (дисквалификация) произошел до начала матча,
         коэффициент выигрыша по ставкам принимается равным «1», кроме ставок на
          результат участника в соревновании. Если теннисный матч прерван, не
           завершен в тот же день и отложен, ставки на него остаются в силе до
            окончания турнира, в рамках которого этот матч проводился, пока матч
             не будет доигран или не произойдет отказ одного из участников.
        </li><li>6.10.Если в событии (матче, соревновании или бое) указывается одна пара участников
        (команд, спортсменов), а впоследствии состав пары изменяется, то все ставки на это событие отменяются.
        </li><li>6.11.В командных соревнованиях, при замене одного или нескольких участников любой
         из команд по любой причине, ставки на исход всего матча остаются в силе.
        </li><li>6.12.В парных матчах, если указан состав пар, при замене хотя бы одного из участников
         коэффициент выигрыша по ставкам будет равен «1»; если состав не указан, ставки остаются в силе.
        </li><li>6.13.В соревнованиях, в которых употребляются понятия «хозяева» и «гости»,
         в случае переноса события: на нейтральное поле – ставки сохраняют силу;
          на поле команды-соперника – ставки сохраняют силу.
        </li><li>6.14.Если понятия «хозяева» и «гости» неприменимы для события (например, в одиночных и парных видах спорта),
         то при изменении места проведения события все ставки на него сохраняют силу. Команды NBA, NHL,
          AHL, CHL, OHL, WHL, Хоккейной лиги восточного побережья Северной Америки могут располагаться
           как в прямой последовательности (хозяева – гости), так и в обратной. В случае обратного
            расположения возврат по ставкам не производится.
        </li><li>6.15.В товарищеских матчах, клубных товарищеских турнирах при изменении
         места проведения события все ставки на него сохраняют силу.
        </li><li>6.16.Виды спорта или ситуации, для которых не существует отдельного описания
         особенностей приема и расчета ставок, подчиняются Общим правилам.
        </li><li>6.17.В спорных ситуациях, не имеющих прецедента, окончательное решение принимается букмекерской компанией.
        </li><li>6.18.В случае несоответствия данных из различных источников информации
         (даты, времени, результата, названия команды) букмекерская компания приостанавливает
          выплату выигрыша до полного разбирательства подлинности таких сведений.
           Если результат завершенного события, представленный на официальном сайте,
           отличается от данных телевизионной трансляции, то Компания оставляет за
           собой право производить расчет по данным телевизионной трансляции.
        </li><li>6.19.В случае выявления ошибки в программе вывода коэффициентов и признания
         этой ошибки компанией, все сделанные ставки по этим событиям считаются
         выигрышными и выплата по ставкам производится с коэффициентом выигрыша, равным «1».
        </li>
      </ul>

      <h2>7.Стороны</h2>
      <ul>
        <li>7.1.Все пункты Соглашения, включающие местоимения «мы», «нас», «наш» или «Компания»,
        относятся к Компании, с которой клиент заключает договор, в соответствии с приведенным выше пунктом 1.
        </li>
      </ul>

      <h2>8.Изменение условий</h2>
      <ul>
        <li>8.1.По ряду причин, а именно: коммерческим, юридическим, а также по причинам,
        связанным с обслуживанием клиентов, у нас есть право вносить любые изменения в
        Соглашение. Актуальные пункты Соглашения и даты их вступления в силу представлены
        на сайте. Клиент несет личную ответственность за ознакомление с действующим Соглашением.
         У Компании есть право вносить изменения в работу Интернет-сайта в любое время
          и без предварительного информирования Клиентов.
        </li><li>8.2.В случае несогласия с измененными пунктами Соглашения Клиент должен
        прекратить использование Веб-сайта. Дальнейшее использование Веб-сайта, после
         вступления в силу измененных пунктов Соглашения, будет расцениваться как его
          полное принятие, независимо от того, получил ли клиент соответствующее
           уведомление или узнал про изменения из обновленного Соглашения.
        </li>
      </ul>

      <h2>9.Право требования</h2>
      <ul>
        <li>9.1.Лицам, не достигшим 18 летнего возраста или возраста (далее - Допустимый возраст),
         который является обязательным для законного участия в азартных играх по законодательству
          конкретной юрисдикции, запрещено пользоваться Веб-сайтом. Использование Веб-сайта лицами,
           не достигшими Допустимого возраста, является прямым нарушением Соглашения. В связи с этим,
            у нас есть право запрашивать документы, которые могут подтвердить возраст Клиента.
             Клиенту может быть отказано в предоставлении услуг, и действие его счета может быть
              приостановлено, если по нашему требованию не будут предъявлены доказательства того,
               что возраст Клиента соответствует Допустимому.
        </li><li>9.2.Азартные онлайн-игры запрещены законом в некоторых юрисдикциях. Принимая
         Соглашение, Клиент осознает, что Компания не может предоставить гарантии или
          юридическую консультацию относительно легитимности использования Веб-сайта на
           территории юрисдикции, в которой находится Клиент. Мы не можем утверждать,
            что услуги Веб-сайта не нарушают законов юрисдикции Клиента. Клиент
             пользуется услугами Веб-сайта по собственному желанию, и берет на
             себя полную ответственность, хорошо осознавая все возможные риски.
        </li><li>9.3.Мы не стремимся и не намереваемся предоставить Клиенту услуги,
         нарушающие законодательство его юрисдикции. Принимая Соглашение,
          Клиент подтверждает и гарантирует, что использование Веб-сайта
           соответствует законам и правилам, действующим на территории
            его юрисдикции. Мы не несем ответственности за незаконное пользование услугами Веб-сайта.
        </li><li>9.4.По юридическим причинам мы не принимаем Клиентов из следующих стран:

        <ul><li>
        9.4.1.Абсолютное ограничение. Не принимаются клиенты из США, Канады,
             Великобритании, Испании, Латвии, Литвы, Нидерландов, Франции, Италии,
              Швейцарии, Албании, Барбадоса, Пакистана, Сирии, Южного Судана,
               Каймановых островов, Гаити, Ямайки, Северной Кореи, Мальты и Панамы.
                Лицам, проживающим или находящимся на территории этих стран,
                 не разрешается открывать счета или вносить денежные средства
                  на Веб-сайте. В списке юрисдикций возможны изменения, и
                   они могут быть произведены Компанией без предварительного
                    уведомления Клиентов. Вы соглашаетесь, что не станете открывать
                     счет или перечислять на него денежные средства, находясь на
                      территории одной из вышеперечисленных юрисдикций.

          </li><li>
        9.4.2.Черный список. В дополнение к пункту 9.4.1, все игры NetEnt
             запрещены в следующих странах: Афганистан, Албания, Алжир, Ангола,
              Австралия, Камбоджа, Эквадор, Гайана, Гонконг, Индонезия, Иран,
               Ирак, Израиль, Кувейт, Лаос, Мьянма, Намибия, Никарагуа, Северная
                Корея, Пакистан, Панама, Папуа-Новая Гвинея, Филиппины, Сингапур,
                 Южная Корея, Судан, Сирия, Тайвань, Уганда, Йемен, Зимбабве, Бельгия,
                  Болгария, Дания, Эстония, Латвия, Литва, Чехия, Мексика, Португалия, Румыния.

          </li><li>
        9.4.3.Ограничения для брендовых игр

                  <ul><li>9.4.3.1.Игры Guns &amp; Roses, Jimi Hendrix и Motörhead не обслуживаются в
                 следующих странах: Афганистан, Албания, Алжир, Ангола, Камбоджа,
                  Эквадор, Гайана, Гонконг, Индонезия, Иран, Ирак, Кувейт, Лаос, Мьянма,
                   Намибия, Никарагуа, Северная Корея, Пакистан, Панама, Папуа-Новая Гвинея,
                    Филиппины, Сингапур, Южная Корея, Судан, Сирия, Тайвань, Уганда, Йемен,
                     Зимбабве, Бельгия, Болгария, Канада, Дания, Эстония, Франция, Италия,
                      Латвия, Литва, Мексика, Португалия, Румыния, Испания, Соединенные Штаты
                       Америки, Великобритания, Австралия, Азербайджан, Китай, Индия, Малайзия,
                        Катар, Россия, Таиланд, Тунис, Турция и Украина.</li><li>9.4.3.2.Игры «Универсальные монстры» (Франкенштейн, Невеста Франкенштейна, Дракула, Проклятие призрака,
                   Существо из Черной лагуны и Человек-невидимка), обслуживаются ИСКЛЮЧИТЕЛЬНО в следующих странах:
                    Андорра, Армения, Азербайджан, Беларусь, Босния и Герцеговина, Бразилия, Грузия, Исландия,
                     Лихтенштейн, Молдова, Монако, Черногория, Норвегия, Россия, Сан-Марино, Сербия, Швейцария,
                      Украина, Хорватия, Македония, Турция, Австрия, Болгария, Кипр, Финляндия, Германия, Греция,
                       Венгрия, Ирландия, Люксембург, Мальта, Нидерланды, Перу, Польша, Словакия, Словения и Швеция.</li></ul>
          </li><li>
        9.4.4.Ограничения для объединенного джекпота. Объединенные джекпоты
            не обслуживаются и не могут быть доступны в следующих странах: Австралия,
             Азербайджан, Китай, Дания, Индия, Израиль, Италия, Япония, Малайзия,
              Катар, Россия, Испания, Таиланд, Тунис, Турция, Объединенные Арабские Эмираты и Украина

          </li></ul>
        </li><li>9.5.Ставки не принимаются:

        <ul><li>
        9.5.1.у лиц, являющихся участниками событий, на которые делаются ставки
             (спортсмены, тренеры, судьи, владельцы или функционеры клубов и иные
               лица, имеющие возможность повлиять на исход события), а также у
               лиц, действующих по их поручению; у лиц представляющих интересы других букмекерских компаний;

          </li><li>
        9.5.2.у других лиц, чье участие в соглашении с букмекерской
            компанией запрещено действующим законодательством.

          </li></ul>
        </li><li>9.6.Ответственность за нарушение пункта 9.5 настоящего Соглашения
         несет участник пари. В случае нарушения данного Соглашения, букмекерская
          компания оставляет за собой право отказать в оплате любого выигрыша
           или в возвращении внесенных сумм, а также аннулировать любые ставки.
            Букмекерская компания не несет ответственности относительно того,
             когда именно ей стало известно, что клиент входит в одну из перечисленных
              выше категорий лиц. Это означает, что букмекерская компания имеет право
               принимать указанные меры в любое время после того, как стало известно,
                что клиент является одним из указанных лиц.
        </li><li>9.7.Если участник пари совершает мошеннические действия в отношении
         букмекерской компании (мульти-аккаунт, использование ПО для автоматизации
           ставок, игра на арбитражных ситуациях, если игровой счет не используется
            для совершения ставок, злоупотребление программами лояльности и т.д.),
             то букмекерская компания оставляет за собой право пресечь такие
              мошеннические действия путём отмены ставок и закрытия игрового счета участника пари.
        </li>
      </ul>

      <h2>10.Открытие счета</h2>
      <ul>
        <li>10.1.Для того чтобы получить доступ ко всем основным услугам Веб-сайта,
         необходимо открыть счет. Для этого, Клиент может произвести регистрацию
          «в 1 клик» или указать адрес своей электронной почты и придумать пароль,
           который в дальнейшем будет использоваться для входа в игровой профиль,
            а также, дополнительно требуется указать личные данные: имя, номер телефона и дату рождения.
        </li><li>10.2.Клиент должен указать настоящий адрес электронной почты в процессе
         регистрации или, в случае регистрации «в 1 клик», изменить адрес на настоящий.
        </li><li>10.3.Для подтверждения достоверности информации Компания имеет
         право запрашивать документы, подтверждающие личность Клиента.
          Если по каким-либо причинам Клиент не предоставляет запрошенные
           для идентификации документы, Компания имеет право приостановить
            или заблокировать профиль Клиента до предоставления им необходимой информации.
        </li><li>10.4.Клиент подтверждает, что, при регистрации на Веб-сайте,
         он указал полную и достоверную информацию о себе, и в том случае,
          если в ней произойдут какие-либо изменения, Клиент обязуется
           незамедлительно внести их в свой профиль. Невыполнение или
            игнорирование данного правила может привести к применению
             ограничений, приостановлению или блокировке профиля, а также к аннулированию платежей.
        </li><li>10.5.В случае возникновения каких-либо вопросов или проблем при регистрации
        на Веб-сайте, Клиент может обратиться в службу поддержки в режиме онлайн-чата
         или позвонить по телефону – 8(800)301-77-89 (круглосуточно).
        </li><li>10.6.Каждый Клиент может открыть на Веб-сайте только один счет.
         Остальные открытые Клиентом счета будут считаться дубликатами.
          У нас есть право закрывать подобные счета и признавать недействительными
           все операции, проведенные с использованием дублирующего счета;

        <ul><li>
        10.6.1.не возвращать Клиентам все вклады и ставки, сделанные с основного
             и дублирующего счета; требовать возвращения всех выигрышей, бонусов и
              денежных средств, полученных при использовании дублирующего счета.
               Клиент обязан возвратить их по первому нашему требованию.

          </li></ul>
        </li>
      </ul>

      <h2>11.Подтверждение личности. Легализация&nbsp;</h2>
      <ul>
        <li>11.1.Пожалуйста обратите внимание на то, что имя и дата рождения,
         указанные в Вашем профиле должны соответствовать вашему действительному имени.
        </li><li>11.2.Имя, указанное в Вашем профиле при регистрации, должно совпадать
         с именем на кредитной карте или иных платежных профилях, используемых
          для пополнения или вывода средств с/на Ваш игровой счет.
        </li><li>11.3.Для подтверждения Вашей личности, адреса и/или платежного
         профиля ("личности") мы можем в любое время запросить:

        <ul><li>
        11.3.1.подтверждение личности (включая, но не ограничиваясь, копии
              действительного паспорта/удостоверения личности и/или используемых платежных карт),

          </li><li>
        11.3.2.подтверждение адреса, включая, но не ограничиваясь,
             недавние счета за коммунальные услуги (не более чем трехмесячной давности),

          </li><li>
        11.3.3.справку из банка о состоянии счетов, относящихся к выбранному
             способу оплаты (не более чем трехмесячной давности).

          </li></ul>
        </li><li>11.4.Если Вы не предоставляете нам эти документы и/или мы не можем
        верифицировать Вашу личность в течение разумного срока, определяемого
        нами, мы оставляем за собой право:

        <ul><li>
        11.4.1.заблокировать профиль Клиента до тех пор пока верификация не будет успешно пройдена,

          </li><li>
        11.4.2.аннулировать все или некоторые произведенные Вами транзакции и заморозить Ваш профиль, и/или

          </li><li>
        11.4.3.заморозить или закрыть Ваш профиль и прекратить действие данного Соглашения.

          </li></ul>
        </li><li>11.5.Мы оставляем за собой право провести проверку безопасности в любое время
         для подтверждения информации о вашей личности, возрасте и других регистрационных
          или платежных данных, предоставленных вами для верификации Вашего пользования
          Услугами (например, для предотвращения Запрещенных действий), соблюдения Вами
           данного Соглашения и проверки ваших финансовых транзакций, производимых в
            рамках использования Услуг, на потенциальное нарушение данного Соглашения.
        </li><li>11.6.Вы даете букмекерской компании право направлять любые запросы касательно Вас,
         использовать и раскрывать любой третьей стороне любую информацию о Вас с целью ее проверки.
        </li><li>11.7.Вы соглашаетесь предоставлять по нашему запросу любую
         дополнительную информацию или документы для проведения Проверки безопасности.
        </li><li>11.8.Эта процедура является обязательным требованием и проводится в
        соответствии с действующими правилами в области азартных игр и законодательными
         требованиями по борьбе с отмыванием денег, в частности, в соответствии с
         Национальным Постановлением об информировании про нестандартные транзакции
         и нашей внутренней политикой по борьбе с отмыванием денег и предотвращению финансирования терроризма.
        </li>
      </ul>

      <h2>12.Имя пользователя, пароль и безопасность</h2>
      <ul>
        <li>12.1.После открытия счета на Веб-сайте, Клиент обязан хранить свой пароль
        и имя пользователя в секрете и не разглашать данную информацию третьим лицам.
         В случае утраты данных необходимых для получения доступа к счету, их можно
         узнать или восстановить, нажав на кнопку «Восстановить пароль».
        </li><li>12.2.Клиент отвечает за сохранность пароля, а также любые действия и
        транзакции, произведенные с его счетом. Кроме того, Клиент несет ответственность
         за все убытки, понесенные им в результате действий третьей стороны.
        </li><li>12.3.В случае нарушения безопасности и несанкционированного доступа
        к счету, Клиент должен незамедлительно известить букмекерскую компанию.
        В случае необходимости Клиент обязан предъявить Компании доказательства
        того, что несанкционированный доступ действительно произошел. Букмекерская
         компания не несет ответственности за ущерб, полученный Клиентом в результате
         неправильного или неосторожного использования имени пользователя и пароля
         третьей стороной или за несанкционированный доступ к счету.
        </li>
      </ul>

      <h2>13.Депозиты, размещение и вывод денежных средств со счета</h2>
      <ul>
        <li>13.1.Для того чтобы сделать ставку на Веб-сайте, Клиенту необходимо
        разместить на своем счету определенную сумму денег.
        </li><li>13.2.Клиент подтверждает и обязуется, что:

        <ul><li>
        13.2.1.денежные средства, внесенные на игровой счет,
            не были получены незаконным или запрещенным путем;

          </li><li>
        13.2.2.Клиент не станет отказываться от совершенных транзакций, не будет отрицать
             и отменять совершенные платежи, что может стать причиной для возврата денег
              третьей стороне и позволить ей уйти от законной ответственности.

          </li></ul>
        </li><li>13.3.Мы не принимаем денежные средства от третьих лиц: друзей, родственников
         или партнеров. Клиент обязан вносить денежные средства только со своего счета,
          банковской карты или системы, зарегистрированной на имя Клиента. В случае
           выявления фактов нарушения данного условия все выигрыши могут быть конфискованы.
        </li><li>13.4.В случае запроса банковского перевода по возврату денежных средств их
         законному владельцу, все расходы и комиссии оплачиваются за счет получателя.
        </li><li>13.5.Мы не принимаем платежи наличными денежными средствами. Мы можем использовать
         для электронных платежей, в том числе входящих платежей и выплат Клиентам, различные
          организации, занимающиеся обработкой электронных платежей или финансовые учреждения.
           За исключением тех случаев, когда правила и условия данных учреждений противоречат
            условиям Соглашения, Клиент полностью принимает такие правила.
        </li><li>13.6.Клиент соглашается не отказываться, не аннулировать и не отменять транзакции,
         проведенные с участием его счета. К тому же в каждом из подобных случаев Клиент
          обязан вернуть или компенсировать Компании сумму не размещенных средств, включительно
           с расходами, которые мы можем понести при сборе вкладов Клиента.
        </li><li>13.7.У Компании есть право блокировать счет Клиента, а также аннулировать
         выплаты и взыскивать выигрыши, в случае возникновения подозрений относительно
          того, что пополнение счета было произведено мошенническим способом. Мы имеем
           право известить соответствующие органы о мошеннических действиях с платежами
            и незаконной деятельности. У нас есть право прибегать к услугам инкассирующих
             агентств для возврата платежей. Компания не несет ответственности за несанкционированное
              использование кредитных карт, независимо от того было заявлено об их краже или нет.
        </li><li>13.8.Мы имеем право использовать положительный баланс счета Клиента для погашения
         денежной суммы, которую Клиент должен возместить Компании. В частности, в случаях
          повторных ставок или пари, согласно пункту 10 («Сговор, действия, вводящие в заблуждение,
             мошенничество и криминальная деятельность) или пункта 13 («Ошибки и недочеты»).
        </li><li>13.9.Клиент полностью осознает и принимает тот факт, что игровой счет не является
        банковским счетом. Следовательно, по отношению к нему не действуют методы страхования,
         пополнения, гарантии и прочие способы защиты со стороны системы страхования вкладов и
          прочих систем страхования. На денежные средства на игровом счету не начисляются проценты.
        </li><li>13.10.Клиент имеет право подать запрос на вывод денег со счета при условии, что:

        <ul><li>
        13.10.1.все платежи, поступившие на счет, прошли проверку, и ни один из них не был отменен или аннулирован;

          </li><li>
        13.10.2.проверочные действия, были проведены надлежащим образом.

          </li></ul>
        </li><li>13.11.При исполнении заявки на вывод средств со счета,
         должны быть учтены следующие моменты:

        <ul><li>
        13.11.1.игровой профиль должен быть полностью заполнен. Также,
             если Клиент регистрировался с помощью функции «в 1 клик», в
              личном профиле ему необходимо произвести замену электронного адреса;

          </li><li>
        13.11.2.если в результате запрошенной операции вывода общая сумма
             выводов денежных средств превышает размер 50 000 руб. или эквивалентную
              сумму в любой другой валюте, то будет проведена процедура идентификации.
               Для ее осуществления Клиенту необходимо отправить нам копию или цифровую
                фотографию документа, удостоверяющего личность Клиента. При пополнении
                 счета посредством кредитной карты, необходимо прислать копию изображения
                  обеих сторон данной карты. В номере карты должны быть видны первые шесть
                   и последние четыре цифры, CVV2 код может быть скрыт;

          </li><li>
        13.11.3.Компания имеет право взимать комиссию на покрытие расходов,
             связанных с выводом денежных средств не задействованных в игре.

          </li><li>
        13.11.4.Сумма вывода до 5 000 рублей или эквивалентная сумма в
            другой валюте, выплачивается на счет клиента в срок от 1 до 60 минут
             с момента подачи заявки, за исключением технических неполадок со стороны банка или платежной системы.

          </li><li>
        13.11.5.Сумма вывода от 5 000 рублей или эквивалентная сумма в другой валюте,
             выплачивается на счет клиента в течение 5 дней с момента подачи заявки.

          </li><li>
        13.11.6.Возможны прочие задержки до 3-х рабочих дней в зависимости от банка или иной платежной системы.

          </li><li>
        13.11.7.Иные пути вывода выигрышных средств обговариваются отдельно с Администрацией Веб-сайта.&nbsp;

          </li></ul>
        </li><li>13.12.Компания не предоставляет услуги по обмену денежных
        средств. У нас есть право закрывать подобные счета и:

        <ul><li>
        13.12.1.удерживать комиссию в размере наших затрат по выводу средств не задействованных в игре;&nbsp;

          </li><li>
        13.12.2.признавать недействительными все транзакции,
            проведенные с использованием основного и дублирующих счетов;

          </li><li>
        13.12.3.не возвращать Клиентам все вклады,
            сделанные с основного и дублирующих счетов.

          </li></ul>
        </li><li>13.13.Для активации вывода денежных средств игроку необходимо сделать ставку
         с коэффициентом не менее 1.3 на сумму не менее 100% от каждого внесенного депозита
          или не менее 100% от каждого внесенного депозита задействовать (играть, использовать)
           в играх Казино или живых играх.
        </li><li>13.14.В случае, если игровая активность игрока была ниже указанной в пункте 13.13,
         при выводе денежных средств Букмекерская компания вправе удержать расходы на комиссии
          платежной системы с клиента (до 15% за каждую транзакцию).
        </li><li>13.15.В случае, если сумма выигрыша составляет более 5 000 000 (пять миллионов рублей),
         букмекерская компания оставляет за собой право установить ежедневный лимит для вывода,
          который рассчитывается индивидуально для каждого Клиента.
        </li>
      </ul>

      <h2>14.Условия и положения для услуг букмекерской компании:</h2>
      <ul>
        <li>14.1.Вы согласны оплатить все услуги и/или товары или другие дополнительные услуги,
         заказанные Вами на Веб-сайте, а также все дополнительные расходы (при необходимости),
          включая, но не ограничиваясь, всевозможные налоги, пошлины и т.д. Вы несете полную
           ответственность за своевременную оплату всех платежей. Провайдер платежных услуг
            обеспечивает лишь проведение платежа в обозначенном Веб-сайтом размере и не несет
             ответственности за уплату пользователем Веб-сайта вышеупомянутых дополнительных
              сумм. После нажатия кнопки "Оплата", считается, что платеж обработан, и он
               безвозвратно выполнен. Нажав кнопку "Оплата", Вы соглашаетесь с тем, что
                Вы не сможете отозвать платеж или потребовать его отзыва. Размещая заказ
                 на Веб-сайте, Вы подтверждаете и указываете, что не нарушаете законы любого
                  государства. Дополнительно, принимая положения данных правил (и/или Условий
                   и положений), Вы, как владелец платежной карты, подтверждаете, что имеете
                    право пользоваться товарами и/или услугами, предлагаемыми на Веб- сайте.
        </li><li>14.2.В случае если Вы используете услуги Веб-сайта, предлагающего такие специфические
         услуги как игровой сервис, Вы предоставляете юридически обязывающее подтверждение,
          что Вы достигли или уже превысили возраст совершеннолетия, который является законодательно
           разрешенным в Вашей юрисдикции для того, чтобы пользоваться услугами предоставленными Веб-сайтом.
        </li><li>14.3.Начиная использовать услуги Веб-сайта, Вы берете на себя юридическую ответственность
         за соблюдение законодательства любого государства, где используется данная услуга, и
          подтверждаете, что провайдер платежных услуг не несет никакой ответственности за любое
           незаконное или несанкционированное подобное нарушение. Соглашаясь использовать услуги
            Веб-сайта, Вы понимаете и принимаете, что обработка любого Вашего платежа осуществляется
             провайдером платежных услуг, и не существует законного права на возврат уже купленных
              услуг и/или товаров или других возможностей отмены платежа. Если Вы хотите отказаться
               от использования услуги для следующей покупки услуги и/или товаров, Вы можете отказаться
                от услуги, используя Личный кабинет на Веб-сайте.
        </li><li>14.4.Провайдер платежных услуг не несет ответственность за отказ/невозможность обработать данные,
         связанные с Вашей платежной картой, либо за отказ, связанный с неполучением от банка-эмитента
          разрешения на проведение платежа с использованием Вашей платежной карты. Провайдер платежных
           услуг не несет ответственность за качество, объем, цену любой услуги и/или товара, предложенных
            Вам или купленных Вами на Веб-сайте, используя Вашу платежную карту. Оплачивая любые услуги
             и/или товары Веб-сайта, Вы, в первую очередь, обязаны соблюдать правила пользования Веб-сайта.
              Просим учитывать, что только Вы, как владелец платежной карты, несете ответственность за
               своевременную оплату любой услуги и/или товара, заказанной Вами через Веб-сайт и за все
                связанные с этой оплатой дополнительные расходы/комиссии. Провайдер платежных услуг
                 является только исполнителем платежа в указанном Веб-сайтом размере и не несет ответственность
                 за любое ценообразование, общие цены и/или общие суммы.
        </li><li>14.5.В случае возникновения ситуации, связанной с Вашим несогласием с вышеизложенными
         условиями и/или другими причинами, просим Вас своевременно отказаться от выполнения
          платежа и, при необходимости, обратиться непосредственно к администратору/поддержке Веб-сайта.
        </li>
      </ul>

      <h2>15.Сговор, действия, вводящие в заблуждение, мошенничество и криминальная деятельность</h2>
      <ul>
        <li>15.1.Компания не рекомендует заключать пари группой Клиентов с одного
         IP-адреса или из одной локальной сети, а также по предварительному согласованию.
          Вышеуказанные действия могут быть расценены как сговор, мошенничество, действия, вводящие в заблуждение.
        </li><li>15.2.Компания не несет никакой ответственности относительно того, когда именно
         ей стало известно, либо установлено впоследствии, что пари было заключено
          с нарушением правил. У нас есть право закрывать подобные счета и:

        <ul><li>
        15.2.1.признавать недействительными все транзакции, не возвращать Клиентам
             все вклады и ставки, сделанные согласованно с другими Клиентами;

          </li><li>
        15.2.2.требовать возвращения всех выигрышей, бонусов и денежных средств,
             полученных в результате сговора. Клиент обязан возвратить их по первому нашему требованию.

          </li></ul>
        </li><li>15.3.Следующие виды деятельности строго запрещены, совершение подобных
         действий будет расценено как прямое нарушение Соглашения:

        <ul><li>
        15.3.1.передача информации третьей стороне;

          </li><li>
        15.3.2.незаконные действия: мошенничество, использование вредоносных программ,
             ботов и ошибок в программном обеспечении Веб-сайта;

          </li><li>
        15.3.3.мошеннические действия, такие как использование украденных,
             клонированных или полученных иным незаконным путем
             данных кредитной или дебетовой карты для пополнения счета;

          </li><li>
        15.3.4.участие в преступной деятельности, отмывании денег и иных видах
             деятельности, участие в которых может повлечь за собой уголовно-правовую ответственность;

          </li></ul>
        </li><li>15.4.У нас есть право приостанавливать, отменять или отзывать выплаты, или выигрыши,
         связанные с бонусными средствами в том случае, если у нас появится
         подозрение в том, что Клиент намеренно злоупотребляет ими.
        </li><li>15.5.Компания предпримет все допустимые и разрешенные законом действия
         для исключения и выявления мошеннических сговоров и их непосредственных
          участников, а также, против этих лиц в обязательном порядке будут приняты
           соответствующие меры. Мы не несем ответственности за потери и ущерб,
            полученный Клиентами в результате мошеннических действий.
        </li><li>15.6.Клиенты обязаны незамедлительно известить нас, если у них возникнут
         подозрения относительно того, что какой-либо Клиент находится в сговоре
          или осуществляет мошеннические действия. Связаться с нами можно посредством
           обращения через Веб-сайт в службу поддержки в режиме онлайн-чата.
        </li><li>15.7.У нас есть право без предварительного уведомления запретить Клиентам
         доступ к Веб-сайту и заблокировать их счета, в том случае если они будут
          заподозрены в мошеннической деятельности. В таких случаях мы не несем
           ответственности по возврату и компенсации денежных средств, имеющихся
            на счетах таких Клиентов. Кроме того, у нас есть право известить
             правоохранительные органы о незаконной деятельности. Клиенты обязаны
              всецело сотрудничать с нами при расследовании подобных случаев.
        </li><li>15.8.Клиентам запрещено использовать услуги и программное обеспечение
         Веб-сайта для совершения любых мошеннических, незаконных действий и
          транзакций в соответствии с законодательством любой конкретной юрисдикции.
           Если будет установлен или выявлен факт совершения Клиентом подобных действий,
            то Компания может приостановить или заблокировать счет Клиента и удержать
             средства, имеющиеся на нем. В таких случаях Клиенты не имеют права
              предъявлять Компании какие-либо претензии.
        </li><li>15.9.На сайте запрещено совершать игровую деятельность от имени третьих лиц
         (номинальных владельцев игрового счета). Игровой профиль подлежит немедленной
          блокировке, в случае если в процессе верификации игрового профиля сотрудником
           службы безопасности будет установлено, что лицо, проходящее верификацию не
            обладает достаточными знаниями в области ставок на спортивные события.
        </li>
      </ul>

      <h2>16.Прочие запрещенные действия на сайте&nbsp;</h2>
      <ul>
        <li>16.1.На Веб-сайте запрещено использовать агрессивную или оскорбительную манеру общения,
         ненормативную лексику, угрозы и применять любые насильственные действия по отношению к
          сотрудникам и другим Клиентам Компании. При несоблюдении Игроком данного пункта правил,
           Компания вправе прекратить коммуникацию с данным Игроком или оставить входящую заявку без рассмотрения.
        </li><li>16.2.Запрещено загружать на Веб-сайт информацию в объеме, способном вызвать сбои
         в работе Веб-сайта и осуществлять какие-либо другие действия, которые могут повлиять
          на работу ресурса. В данном случае имеются в виду вирусы, вредоносные программы,
           массовая рассылка и спам – все перечисленное строго запрещено. Кроме того,
            Клиентам запрещено удалять, изменять и редактировать информацию, размещенную на Веб-сайте.
        </li><li>16.3.Клиенты могут пользоваться Веб-сайтом исключительно в развлекательных целях.
         Запрещено в целом или частично копировать Веб-сайт.
        </li><li>16.4.Клиенты обязуются не совершать действия, направленные на взлом системы
         безопасности Веб-сайта, получение незаконного доступа к закрытым данным или
          DDoS-атаки. По отношению к Клиентам, заподозренным в нарушении данного правила,
           будут применены соответствующие меры: полный запрет на доступ к Веб-сайту и
            блокировка профиля. Кроме того, мы имеем право оповестить ответственные
             о незаконных действиях Клиента.
        </li><li>16.5.Мы не несем ответственности за потери и убытки, которые могут
         понести наши Клиенты или третьи стороны вследствие технических сбоев,
          спровоцированных вирусными атаками или другими вредоносными действиями, направленными на Веб-сайт.
        </li><li>16.6.Строго запрещено передавать и продавать профиль одного Клиента другому.&nbsp;
        </li>
      </ul>

      <h2>17.Сроки и порядок прекращения действия Соглашения</h2>
      <ul>
        <li>17.1.Компания имеет право удалить профиль Клиента (в также его имя и пароль)
         без предварительного уведомления в следующих случаях:'

        <ul><li>
        17.1.1.Компания приняла решение прекратить предоставление Услуг всем или определенной части Клиентов;&nbsp;

          </li><li>
        17.1.2.профиль Клиента каким-то образом связан с ранее удаленным профилем;

          </li><li>
        17.1.3.Профиль Клиента связан с заблокированным в настоящее время профилем,
             в таком случае Компания имеет право закрыть профиль независимо от рода связи
              и полностью заблокировать учетные данные этих профилей. За исключением случаев,
               указанных в Соглашении, денежные средства, находящиеся на профиле Клиента,
                будут возвращены ему в течение определенного срока, сразу после заполнения
                 заявки и удержания суммы, которую Клиент должен Компании;

          </li><li>
        17.1.4.Клиент участвует в криминальном сговоре или пытается взломать систему;

          </li><li>
        17.1.5.Клиент вмешивается в работу программного обеспечения
             Веб-сайта или пытается проводить какие-либо иные действия;

          </li><li>
        17.1.6.Клиент использует свой профиль в целях, которые могут рассматриваться
             как незаконные согласно законодательству любой конкретной юрисдикции;

          </li><li>
        17.1.7.Клиент публикует на Веб-сайте информацию оскорбительного или унизительного характера.&nbsp;

          </li></ul>
        </li><li>17.2.Компания имеет право закрыть профиль Клиента или отменить
         действие Соглашения, послав соответствующее уведомление на электронный
          адрес, указанный в контактной информации. В случае таких действий
           с нашей стороны, за исключением ситуаций указанных в пункте 15
            («Сговор, действия, вводящие в заблуждение, мошенничество и криминальная деятельность»)
             и 22 («Нарушение условий») настоящего Соглашения, Компания обязана вернуть денежные
              средства со счета Клиента. Если по каким-либо причинам мы не будем иметь
               возможности связаться с Клиентом, денежные средства будут временно перемещены
                на счет букмекерской компании или контролирующего органа.
        </li>
      </ul>

      <h2>18.Изменения на Веб-сайте</h2>
      <ul>
        <li>18.1.Мы имеем право в любое время по собственному усмотрению,
         вносить изменения или дополнения в услуги, предлагаемые на
          Веб-сайте в целях поддержки и обновления ресурса.
        </li>
      </ul>

      <h2>19.Системные ошибки</h2>
      <ul>
        <li>19.1.Если в процессе игры произойдет сбой в работе Веб-сайта, Компания
         постарается исправить ситуацию в кратчайшие сроки. Мы не несем ответственности
          за неполадки в средствах информационных технологий, возникшие в результате
           работы оборудования, используемого Клиентами для доступа к Веб-сайту,
            а также за сбои в работе интернет провайдеров.
        </li>
      </ul>

      <h2>20.Ошибки и недочеты</h2>
      <ul>
        <li>20.1.Компания имеет право ограничивать или отменять любые ставки.
        </li><li>20.2.Если Клиент использовал денежные средства, поступившие на его счет в
         результате ошибки, для размещения ставок и участия в игре, мы можем
          аннулировать подобные ставки и любые выигрыши, полученные с их помощью.
           Если по таким ставкам была произведена выплата денежных средств, то данные
            суммы следует считать переданными Клиенту в доверительное управление,
             Клиент обязан возвратить их по первому нашему требованию.
        </li><li>20.3.Компания и поставщики услуг не несут ответственности за ущерб, в том
         числе потерю выигрышей, произошедший в результате ошибок со
          стороны Клиента или с нашей стороны.
        </li><li>20.4.Компания, ее дистрибьюторы, филиалы, лицензиаты, дочерние предприятия,
         а также сотрудники и руководители не несут ответственности за любые убытки
          и ущерб, возникший по причине перехвата или неправильного
          использования информации, передаваемой через Интернет.
        </li>
      </ul>

      <h2>21.Ограничение нашей ответственности</h2>
      <ul>
        <li>21.1.Клиент самостоятельно принимает решение использования услуг Веб-сайта,
         и любые действия и их последствия являются следствием личного выбора Клиента,
          сделанного по его усмотрению на собственный страх и риск.
        </li><li>21.2.Работа Веб-сайта осуществляется в соответствие с пунктами данного Соглашения.
         Компания не предоставляет никаких дополнительных гарантий или заверений по отношению
          к Веб-сайту и услугам, которые предлагаются на нем, и этим исключает свою
           ответственность (в степени, предусмотренной законом) в отношении всех имеющихся в виду гарантий.
        </li><li>21.3.Мы не несем ответственности за правонарушения, халатность, убытки
         и ущерб, которые мы не могут быть предусмотреть в данный момент.
        </li><li>21.4.Компания не несет ответственности за содержание ресурсов, доступ к
        которым может быть получен посредством Веб-сайта.
        </li>
      </ul>

      <h2>22.Нарушение условий</h2>
      <ul>
        <li>22.1.Клиент дает свое согласие в полной мере возмещать убытки, защищать
         и отстаивать интересы Компании, ее партнеров, сотрудников и директоров
          от любых притязаний, ответственности, издержек, потерь и расходов, возникших по причине:

        <ul><li>
        22.1.1.нарушения Клиентом условий Соглашения;

          </li><li>
        22.1.2.нарушения Клиентом законов и прав третьих лиц;

          </li><li>
        22.1.3.получения доступа к услугам Сайта любым другим лицом при помощи
             идентификационных данных Клиента с его разрешения или без него, или;

          </li><li>
        22.1.4.присвоения выигрышей, полученных таким путем.

          </li></ul>
        </li><li>22.2.В случае если Клиент нарушит условия Соглашения, мы имеем право:

        <ul><li>
        22.2.1.известить Клиента о том, что своими действиями он нарушает условия Соглашения,
             и потребовать немедленного прекращения запрещенных действий;

          </li><li>
        22.2.2.приостановить действие счета Клиента;

          </li><li>
        22.2.3.заблокировать счет Клиента без предварительного уведомления;

          </li><li>
        22.2.4.взыскать со счета Клиента сумму выплат, выигрышей или бонусов,
             приобретенных в результате нарушения.

          </li></ul>
        </li><li>22.3.У нас есть право аннулировать имя пользователя и пароль
         Клиента в случае несоблюдения им любого из пунктов Соглашения.
        </li>
      </ul>

      <h2>23.Права на объекты интеллектуальной собственности</h2>
      <ul>
        <li>23.1.Все содержание Веб-сайта является объектом авторского права и прочих
        прав собственности, принадлежащих Компании. Все загружаемые и печатные материалы,
         присутствующие на Веб-сайте могут быть загружены только на один компьютер.
          Распечатывать данные материалы разрешено исключительно в целях личного и некоммерческого использования.
        </li><li>23.2.Пользование Веб-сайтом не дает Клиенту никаких прав на интеллектуальную
         собственность, принадлежащую Компании или третьей стороне.
        </li><li>23.3.Запрещается любое использование или воспроизведение торговой марки,
         товарных знаков, логотипов и прочих рекламных материалов, представленных
          на Веб-сайте, без соответствующего согласия Компании.
        </li><li>23.4.Клиент понесет ответственность за любой ущерб, расходы или затраты,
         возникшие в результате совершения им запрещенной деятельности. Клиенты должны
          незамедлительно известить Компанию если им станет известно о совершении
          какой-либо запрещенной деятельности и оказать всю необходимую помощь в
           проведении расследования, которое Компания может провести с учетом предоставленной информации.
        </li>
      </ul>

      <h2>24.Персональные данные Клиентов</h2>
      <ul>
        <li>24.1.Мы обязаны соблюдать требования к защите персональных данных в том виде,
         в котором Компания использует личную информацию, собранную в результате визита
          пользователя на Веб-сайт. Компания с максимальной серьезностью относится к
           обязательствам по обработке персональных данных наших Клиентов. Компания
            обрабатывает персональные данные, предоставленные Клиентами с полным
             соблюдением политики конфиденциальности.
        </li><li>24.2.Предоставляя персональные данные, Клиенты соглашаются с тем, что у
         Компании есть право на обработку персональных данных для целей, указанных
          в Соглашении, или же для соблюдения нормативных и юридических обязательств.
        </li><li>24.3.В соответствии с политикой конфиденциальности, мы обязуемся не раскрывать
        и не передавать персональные данные наших Клиентов третьим лицам, за исключением
         систем обработки информации и работников, которые по необходимости могут
          использовать ее исключительно с целью более качественного предоставления услуг.
        </li><li>24.4.Мы храним копии всей корреспонденции, полученной от Клиентов,
        чтобы в точности зафиксировать всю полученную информацию.
        </li>
      </ul>

      <h2>25.Использование файлов «Cookie» на Веб-сайте</h2>
      <ul>
        <li>25.1.
        Мы используем файлы «cookie» для обеспечения функциональности Веб-сайта. «Cookie» – это
            специальный текстовый файл небольшого размера, который сохраняется на компьютере
            пользователя при посещении Веб-сайта, с его помощью мы можем узнавать конкретных
            пользователей при повторном посещении Сайта. Дополнительные сведения о контроле и
            удалении файлов «cookie» можно найти на сайте: www.aboutcookies.org (
            <a href="http://www.aboutcookies.org" target="_blank" rel="noreferrer">
              http://www.aboutcookies.org
            </a>
            ). Следует учитывать, что удаление наших файлов «cookie» может стать причиной
            блокирования доступа к некоторым разделам и функциям Веб-сайта.

        </li>
      </ul>

      <h2>26.Жалобы и уведомления</h2>
      <ul>
        <li>26.1.
        При возникновении любых жалоб и претензий относительно работы Веб-сайта, прежде всего,
            следует в кратчайшие сроки направить свою претензию в службу поддержки в режиме
            онлайн-чата через Веб-сайт Компании (тел. круглосуточной поддержки Компании –
            <a href="tel:8(800)301-77-89">
              8(800)301-77-89
            </a>
            ) или на электронный адрес компании –
            <a href="mailto:support@rysebet.com" target="_blank" rel="noreferrer">
              support@rysebet.com
            </a>
            .

        </li><li>26.2.Клиент соглашается с тем, что запись разногласия, хранящаяся на сервере,
         может быть использована в качестве свидетельства при рассмотрении претензии.
        </li>
      </ul>

      <h2>27.Порядок разрешения спорных ситуаций</h2>
      <ul>
        <li>27.1.
        Игрок может оспаривать любые ситуации. Компания всесторонне и объективно рассматривает
            все спорные моменты в случае предоставления конкретной информации – фактов и доводов.
            Спорную информацию, с приложением фактов и доводов, необходимо предоставить в письменном
            виде в режиме онлайн-чата через Веб-сайт Компании или на электронный адрес службы
            поддержки Компании –
            <a href="mailto:support@rysebet.com" target="_blank"  rel="noreferrer">
              support@rysebet.com
            </a>
            . В течение 14 дней с момента получения письма жалобы или пожелания будут рассмотрены, и
            Клиент получит уведомление о результате рассмотрения в ответном письме. При
            необходимости проведения дополнительных проверок, Компания вправе продлить срок
            рассмотрения обращения до 30 дней с момента его получения. В тексте письма запрещено
            использовать ненормативную лексику, любого вида оскорбления и ложные данные.

        </li>
      </ul>

      <h2>28.Обстоятельства непреодолимой силы</h2>
      <ul>
        <li>28.1.Компания не несет ответственности за задержки или невыполнение обязательств,
         перечисленных в Соглашении, в том случае, если они обусловлены обстоятельствами
         непреодолимой силы, под которыми следует понимать стихийные бедствия, войны,
         гражданские волнения, трудовые споры, перебои в общественных коммунальных сетях,
         DDoS-атаки или другие атаки в сети Интернет, способные оказать негативное
         воздействие на работу Веб-сайта.
        </li><li>28.2.В период действия обстоятельств непреодолимой силы деятельность Интернет-сайта
         считается приостановленной, и на протяжении данного периода в исполнении обязательств
          действует отсрочка. Компания обязуется использовать все доступные ей средства для
           поиска решений, позволяющих в полной мере выполнять свои обязательства перед Клиентами,
            вплоть до окончания действия обстоятельств непреодолимой силы.
        </li>
      </ul>

      <h2>29.Отказ от обязательств</h2>
      <ul>
        <li>29.1.Если мы не сможем обеспечить исполнение Клиентом какого-либо из обязательств,
         или мы сами не сможем воспользоваться любым из средств правовой защиты, на которые
          у нас есть право, то это не следует расценивать как отказ от данных средств правовой
           защиты или как причину, освобождающую Клиента от выполнения обязательств.
        </li>
      </ul>

      <h2>30.Делимость соглашения</h2>
      <ul>
        <li>30.1.Если какой-либо из пунктов Соглашения станет недействительным,
        нелегитимным или утратившим юридическую силу, то данное положение будет
         отделено от остальной части Соглашения, которая в полной мере сохранит
          свою юридическую силу. В таких случаях часть, которая считается недействительной,
           будет изменена согласно обновленным нормам.
        </li>
      </ul>

      <h2>31.Ссылки</h2>
      <ul>
        <li>31.1.На Веб-сайте могут присутствовать ссылки на другие ресурсы, которые
         находятся вне нашего контроля. Мы не несем ответственности за содержание
         других сайтов, действия или бездействие их владельцев и за содержание рекламы
          третьей стороны и спонсорства. Гиперссылки на другие сайты предоставляются
           исключительно в информационных целях. Клиенты используют их на собственный страх и риск.
        </li>
      </ul>
  `
};

export default comonRules;
