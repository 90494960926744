import image from '@assets/partners.jpg';

export const partnerProgram = {
  id: 'partner-program',
  title: 'Партнерская программа RYSEBET.COM',
  content: `
      <h1>Партнерская программа RYSEBET.COM</h1>
  <ul>
    <li>Новый конкурентный игрок на рынке</li>
    <li>Быстро растущая компания</li>
    <li><b>40% Rev Share</b></li>
    <li>Большой выбор промо-материалов</li>
    <li>Хорошие условия для менеджеров</li>
    <li>Регулярные выплаты</li>
  </ul>
  <p>По вопросам сотрудничества пишите на почту: <b><a href="mailto:support@rysebet.com"rel="noreferrer">support@rysebet.com</a></b></p>
  <p>Или в телеграм <b><a href="https://t.me/rysebet_operator" target="_blank" class="static-pages__link" rel="noreferrer">https://t.me/rysebet_operator</a></b></p>
  <img src="${image}" alt="">
  `
};
