
export const bonuses = [
  {
    id: 'freebet',
    title: 'Фрибет 1000р для новых пользователей букмекерской компании RYSEBET.',
    description: '',
    preview: '',
    content: `
        <h1>Фрибет 1000р для новых пользователей букмекерской компании RYSEBET.</h1>
        <p><b>Как получить фрибет?</b></p>
        <ol>
        <li>Пройди регистрацию на сайте <a href="/">rysebet.com</a></li>
        <li>Сделай депозит. Пополни свой счёт единовременным платежом на
          сумму от 2000рублей и получи на свой основной счет дополнительных 1000р. Для получения, пиши оператору в чат «Хочу фрибет»:
          <a href="https://t.me/rysebet_operator" target="_blank"  rel="noreferrer">https://t.me/rysebet_operator</a></li>
        </ol>

        <p><i>* Деньги для фрибета начисляются Вам на основной счет, а не на бонусный и процедура отыграша максимально простая.</i></p>
        <p><b>Для того, чтобы их вывести нужно их отыграть. Процедура отыграша:</b></p>
        <ol>
        <li>Нужно проставить сумму, которая у Вас на аккаунте два раза.</li>
        <li>Ставки «ординары», «лайв» и «экспрессы»</li>
        <li>Коэффициент от 1.8+</li>
        </ol>
        <p>
          Если пополнили счет на 2000р, то Вы получаете еще 1000р фрибета. На счету 3000р и Вам их нужно проставить два раза. Или полностью, или частично. Можно ставить и по 100-200р, это решаете Вы сами.
          Если сумма пополнения выше 5000р, то отыграть Вам нужно только фрибет, а не всю сумму.
        </p>
        <p>Примечание: </p>
        <ul>
        <li>Фрибет доступен только новым пользователям.</li>
        <li>Отыграные деньги становятся моментально доступны для вывода.</li>
        <li>Компания оставляет за собой право в любой момент
          акции провести дополнительную верификацию владельца
          аккаунта или ограничить участие игрока в данной акции.</li>
        </ul>
        <p>
          По всем вопросам: <b><a href="https://t.me/rysebet_operator" target="_blank" rel="noreferrer">https://t.me/rysebet_operator</a></b>
        </p>
        <p>
          Наша почта: <b><a href="mailto:support@rysebet.com">support@rysebet.com</a></b>
        </p>
    `,
  },
];

export const bonusesUA = [];

export const bonusesEN = [];

type BonusItem = typeof bonuses[number];

export const bonusessListByLang: Record<string, BonusItem[]> = {
  default: bonuses,
  'ru': bonuses,
};
