// import { NavLink } from 'react-router-dom';
import { bemCn } from '@shared/utils/helpers/bem-cn';
import { PropsWithCN } from '@shared/types/common';
import Container from '@shared/components/container/container';
import Breadcrumbs from '@shared/components/breadcrumbs/breadcrumbs';
// import IconSvg from '@/shared/components/icon-svg/icon-svg';
import RuleContentMobile from './components/rule-content-mobile/rule-content-mobile';
import { useScrollToTop } from '@/shared/hooks/use-scrolltotop';
import './rules-screen.scss';
import comonRules from './utils/rules';
import privacyPolicy from './utils/privacy-policy';
import { userAgreement } from './utils/user-agreement';
import { partnerProgram } from './utils/partner-program';
import { support } from './utils/support';

const b = bemCn('rules-screen');

const RulesScreenMobile = ({ className }: PropsWithCN) => {
  useScrollToTop();
  const rulesItems = [comonRules, privacyPolicy, userAgreement, partnerProgram, support];

  return (
    <div className={b(null, className)}>
      <Container className={b('wrapper')}>
        <Breadcrumbs />

        <div className={b('section')}>
          <div className={b('menu')}>

            <ul className={b('menu-list')}>
              {rulesItems.map((item) => (
                <RuleContentMobile
                  key={item.id}
                  id={item.id}
                  title={item.title}
                  content={item.content}
                />
              ))}
            </ul>
          </div>

        </div>

      </Container>
    </div>
  );

};


export default RulesScreenMobile;
