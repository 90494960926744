import logotype from '@assets/logotype.svg';
import { preloadImages } from './shared/utils/helpers/preload';

export const TOKENT_KEY_NAME = 'rysebet-token';
export const DEFAULT_CURRENCY = 'UAH';
export const DEFAULT_TIMEZONE = 3;
export const DEFAULT_LANG = 'uk';
export const AVAILABLE_LANGS = ['ru', 'uk', 'en', 'kk', 'az', 'uz', 'da'];
export const ONLY_CURRENCY: string | null = null;
export const EXCLUDED_CURRENCY: string[] | null = null;
export const AVAILABLE_THEMES = ['light-blue'];
export const DEFAULT_THEME = 'light-blue';
export const COMPANY_NAME = 'Rysebet';
export const SUPPORT_LINK = 'https://t.me/rysebet_operator';
export const SOCIAL_LINK_TG = 'https://t.me/rysebett';
export const SOCIAL_LINK_INST = 'https://www.instagram.com/rysebet/';
export const SOCIAL_LINK_VK = '';
export const LOGO_BOOMAKER_RATINGS = '#';
export const ANDROID_APP = '/rysebet.apk';
export const EXCLUDE_CASINO_CATEGORY = ['gameslobby', 'roshambo', 'arcade', 'moon', 'jetx'];
export const DEFAULT_LOGOTYPE = logotype;
export const MainLogotype = {
  DARK_PURPLE: logotype,
  BLUE: logotype,
  DARK_BLUE: logotype,
  LIGHT_BLUE: logotype,
};
export const CouponLogotype = {
  DARK_PURPLE: logotype,
  BLUE: logotype,
  DARK_BLUE: logotype,
  LIGHT_BLUE: logotype,
};

export const AppFeature = {
  DIGITAIN_GAMES: false,
  BONUS_AND_PROMO: true,
  REGISTRATION_TELEGRAM: false,
  REGISTRATION_PHONE: false,
};

preloadImages([logotype]);
