import { NavLink } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { bemCn } from '@shared/utils/helpers/bem-cn';
import { PropsWithCN } from '@shared/types/common';
import Container from '@shared/components/container/container';
import Breadcrumbs from '@shared/components/breadcrumbs/breadcrumbs';
import TextContent from '@/shared/components/text-content/text-content';
import { useScrollToTop } from '@/shared/hooks/use-scrolltotop';
import './rules-screen.scss';
import comonRules from './utils/rules';
import privacyPolicy from './utils/privacy-policy';
import { userAgreement } from './utils/user-agreement';
import { partnerProgram } from './utils/partner-program';
import { support } from './utils/support';

const b = bemCn('rules-screen');
const RulesScreen = ({ className }: PropsWithCN) => {
  useScrollToTop();
  const rulesItems = [comonRules, privacyPolicy, userAgreement, partnerProgram, support];
  const { slug } = useParams();

  const content = slug ? rulesItems.find((item) => item.id === slug)?.content : rulesItems[0].content;

  return (
    <div className={b(null, className)}>
      <Container className={b('wrapper')}>
        <Breadcrumbs />
        {/* <h1 className={b('title')}>Страница с правилами</h1> */}

        <div className={b('section')}>
          <div className={b('menu')}>
            {/* <h3 className={b('menu-title')}>Правила</h3> */}
            <ul className={b('menu-list')}>
              {rulesItems.map((item) => (
                <li
                  className={b('menu-item')}
                  key={item.id}
                >
                  <NavLink
                    to={`/rules/${item.id}`}
                    className={({ isActive }) => b('menu-item-link', { active: isActive })}
                  >
                    {item.title}
                  </NavLink>
                </li>
              ))}
            </ul>
          </div>

          {content && (
            <TextContent className={b('content')}>
              <div dangerouslySetInnerHTML={{ __html: content }} />
            </TextContent>
          )}
        </div>

      </Container>
    </div>
  );

};


export default RulesScreen;
